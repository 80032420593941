import { ArrowRightIcon } from "@heroicons/react/solid";
import React from "react";
import { Icon } from 'semantic-ui-react'
import { useTranslation} from 'react-i18next';
export default function Navbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="bg-gray-800 md:sticky top-0 z-10">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a className="title-font font-medium mb-4 md:mb-0">
          
          {/* Logo */}
          <a href="#about" className="ml-3 text-xl text-green-500">
            Service Info Solutions
          </a>
        </a>
        

        {/* Navbar Links */}
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700	flex flex-wrap items-center text-base justify-center">
          <a href="#projects" className="mr-5 hover:text-white text-gray-400">
          {t('projects')}
          </a>
          <a href="#skills" className="mr-5 hover:text-white text-gray-400">
          {t('skills')}
          </a>
        </nav>
        <div className="space-x-4">
        <button className="uppercase text-sm px-1 py-1 border-b-2 font-medium text-[#707071] border-transparent"
      // className={`uppercase text-sm px-1 py-1 border-b-2 font-medium text-[#144D9D] !important border-[#144D9D] ${
      //   internalisation !== "fr"

      //     ? "text-[#707071] border-transparent":""
      // }`}
      onClick={() => changeLanguage('fr')}
    >
    
      (FR) Francais
    </button>
    <button className="uppercase text-sm px-1 py-1 border-b-2 font-medium text-[#707071] border-transparent"
      // className={`uppercase text-sm px-1 py-1 border-b-2 font-medium ${
      //   internalisation === "ar"
      //     ? "text-[#144D9D] border-[#144D9D]"
      //     : "text-[#707071] border-transparent"
      // }`}
      
      onClick={() => changeLanguage('en')}
    >
      (EN) Englais
    </button>
    </div>

        <div className="inline-flex items-center border-0 py-1 px-3 mt-4 md:mt-0">
          <a className="mr-5" href="https://github.com/kh-chaiboub?tab=repositories" target="_blank" rel="noopener noreferrer"><Icon circular inverted name='github' /></a>
          <a className="mr-5" href="https://www.linkedin.com/in/khalid-chaiboub-4669231ba/" target="_blank" rel="noopener noreferrer"><Icon circular inverted color='blue' name='linkedin' /></a>
        </div>
        
        {/* Hire Me Button */}
        <a
          href="#contact"
          className="inline-flex items-center text-base text-gray-400 border-0 py-1 px-3 focus:outline-none hover:bg-green-500 hover:text-white rounded w-40 mt-4 md:mt-0">
           {t('hire_Me')}
          <ArrowRightIcon className="w-4 h-4 ml-1" />
        </a>
      </div>
    </header>
  );
}
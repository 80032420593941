import { BadgeCheckIcon, ChipIcon } from "@heroicons/react/solid";
import React from "react";
import { skills } from "../../data";
import { useTranslation} from 'react-i18next';
export default function Skills() {
  const { t, i18n } = useTranslation();
  return (
    <section id="skills">
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-20">
          {/* Skills Header */}
          <ChipIcon className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-4xl font-medium title-font text-white mb-4">
          {t('skills')} &amp;  {t('technologies')}
          </h1>
          <p className="leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-xl">
          {t('skills_and')}
          </p>
        </div>

        {/* Skills List */}
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          {skills.map((skill) => (
            <div key={skill.name} className="p-2 sm:w-1/3 w-full">
              <div className="bg-gray-500 rounded flex p-4 h-full items-center">
                <img
                  src={`/svg/${skill.icon}`}
                  alt={skill.name}
                  className="w-6 h-6 flex-shrink-0 mr-4"
                />
                <span className="title-font font-medium text-white">
                  {skill.name}
                </span>
              </div>
            </div>
          ))}
        </div>
     
      </div>
    </section>
  );
}

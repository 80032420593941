// Projects Data
export const projects = [
  {
    title: "Nadar Website",
    subtitle: "Nadar Website using WordPress",
    description:
      "Maintained Centre Nadar multi-page website as an intern using WordPress with the My traval theme. Increased traffic using SEO techniques and Google Analytics",
    skills: "Built with: WordPress, HTML, CSS",
    image: "./gif-files/nadar.jpg",
    link: "https://nadar.ma/",
    github: ""
  },
  
  {
    title: "Morocco Journey Planner Website",
    subtitle: "Traval Website using WordPress",
    description:
      "Maintained Morocco Journey Planner multi-page website as an intern using WordPress with the My traval theme. Increased traffic using SEO techniques and Google Analytics",
    skills: "Built with: WordPress, HTML, CSS",
    image: "./gif-files/moroccojourneyplanner.jpg",
    link: "https://moroccojourneyplanner.com/",
    github: ""
  },
  {
    title: "ROAD WAY LOCATION",
    subtitle: "ROAD WAY LOCATION Website using Angular & Spring Cloud",
    description:
      "Maintain and manage FA Euro's multi-page website using Angular & Spring Cloud. Responsible for updating, specialized on geolocalisation. ",
    skills: "Built with: Angular & Spring Cloud",
    image: "./gif-files/roadwaylocation.jpg",
    link: "https://github.com/kh-chaiboub/FLOTTE-ANGULAR-UI",
    github: "https://github.com/kh-chaiboub/FLOTTE-ANGULAR-UI"
  },
  {
    title: "Keeper App",
    subtitle: "React Clone of Google Keep",
    description:
      "Keep track of your notes and your to-do's using the Keeper App. Inspired by the Google Keep application.",
    skills: "Built with: React, Material UI",
    image: "./gif-files/keeper-app.gif",
    link: "https://keeper-app-fc.netlify.app/",
    github: "https://github.com/kh-chaiboub?tab=repositories/keeper-app"
  },
 
  {
    title: "Odoo POS",
    subtitle: "POS modile personalised using ERP Odoo",
    description:
      "POS modile personalised using ERP Odoo For Alandalous Company",
    skills: "Built with: Python , XML",
    image: "./gif-files/odoopos.jpg",
    link: "https://github.com/kh-chaiboub/Modul-Boucherie-Al-Andalous",
    github: "https://github.com/kh-chaiboub/Modul-Boucherie-Al-Andalous"
  },
  {
    title: "FAQ Accordion Card",
    subtitle: "Responsive and Interactive FAQ Page",
    description:
      "FAQ accordion card challenge from Frontend Mentor. User can hide/show the answer to a question when the question is clicked.",
    skills: "Built with: HTML, CSS, JavaScript",
    image: "./gif-files/faq-card.gif",
    link: "https://frontend-mentor-faq-accordion-card.netlify.app/",
    github: "https://github.com/kh-chaiboub?tab=repositories/frontend-mentor-challenges/tree/master/faq-accordion-card-main"
  }
  
];


// Skills Data
export const skills = [
  { name: "HTML", icon: "html5.svg" },
  { name: "CSS / Bootstrap", icon: "css.svg" },
  { name: "Tailwind", icon: "tailwind.svg" },
  { name: "JavaScript / jQuery", icon: "javascript.svg" },
  { name: "WordPress", icon: "wordpress.svg" },
  { name: "React", icon: "react.svg" },
  { name: "Angular", icon: "angular.svg" },
  { name: "Spring", icon: "spring.svg" },
  { name: "NextJs 14", icon: "nextjs.svg" },
  { name: "Ruby on Rails", icon: "ruby.svg" },
  { name: "Odoo", icon: "odoo.svg" },
  { name: "Python", icon: "python.svg" },
  { name: "Node / Express.js", icon: "nodejs.svg" },
  { name: "MongoDB / Mongoose", icon: "mongodb.svg" },
  { name: "MySQL", icon: "mysql.svg" },
  { name: "Postgresql", icon: "postgresql.svg" },
  { name: "Git & Version Control", icon: "git.svg" }
];
